import {type FC} from "react";
import Image from 'next/image';

export const logoUrl = '/images/logo-selfstock.svg';
export const logoSquareUrl = '/images/logo-selfstock-square.svg';

export const ResponsiveLogo: FC = function () {
  return <div className={"relative flex h-[48px] items-center justify-center"}>
    {/* Classic logo */}
    <Image 
      src={logoUrl} 
      width={222} 
      height={48} 
      alt="Logo de la marque Selfstock.com"
      className={"hidden object-contain sm:block lg:hidden 2xl:block"}
    />

    {/* Square logo */}
    <Image 
      src={logoSquareUrl} 
      width={48} 
      height={48} 
      alt="Logo de la marque Selfstock.com"
      loading="eager"
      className={"block object-contain sm:hidden lg:block 2xl:hidden"} />
  </div>
};
